<template>
<div class="page-wrapper">
  <Loading v-if="loading"></Loading>
  <div class="page-banner bg" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="container">
      <div class="page-name common-padding">
        <h1>{{ $t("news") }}</h1>
      </div>
    </div>
  </div>
  <div class="news-bg">
    <div class="container common-padding">
      <div class="news">
        <ul>
          <li v-for="item in list" :key="item.id" @click="details(item.id)">
            <div class="news-item">
              <h4>{{ item.title }}</h4>
              <p>{{ item.date }}</p>
            </div>
          </li>
        </ul>
      </div>


      <div class="page">
        <Pagination
            :current-page="currentPage"
            :total-pages="totalPages"
            :show-pagination="showPagination"
            @goto-page="onPageChange"
        />
      </div>
    </div>
  </div>


  <Copyright></Copyright>
</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Copyright from "@/components/Copyright";
import Loading from "@/components/loading";
import {getNews} from "@/network/main";
export default {
  name: "News",
  components: {
    Pagination,
    Copyright,
    Loading,
  },
  data(){
    return{
      loading:true,
      banner:'',
      list:[],
      currentPage: 1, // 当前页码
      totalPages: 1, // 总页数
      showPagination: false, // 是否显示分页
    }
  },
  created() {
    this.getNews()
  },
  methods:{
    //
    getNews(){
      getNews(this.currentPage).then(res =>{
        this.loading = false;
        this.banner = res.data.data.banner.new_message_banner;
        this.list = res.data.data.list;
        this.currentPage = res.data.data.page;
        this.totalPages = res.data.data.lastPage;
        this.showPagination = this.totalPages > 1; // 是否显示分页
      })
    },
    //点击详情
    details(id){
      this.$router.push('/newsDetails/' + id)
    },
    //点击分页按钮
    onPageChange(page) {
      this.currentPage = page;
      this.getNews()
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.news-bg{
  background-color: #cbe9c4;
}
.news{
  min-height: 400px;
}
.news ul{
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news ul li{
  width: 48%;
  border: 1px solid #096e32;
  padding: 30px;
  cursor: pointer;
  margin: 20px 0;
  background: #fff;
}
.news ul li h4{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news ul li p{
  font-size: 14px;
  color: #666;
  padding-top: 15px;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .news ul{
    flex-wrap: wrap;
  }
  .news ul li{
    width: 100%;
    padding: 20px;
    margin: 10px 0;
  }
  .news{
    min-height: 550px;
  }

}
</style>